import * as React from "react";

import Iframe from "react-iframe";
import Modal from "react-modal";

import "./stay-in-touch.css";
import "../buttons.css";

// markup
const CommercialInquiryModal = ({ isOpen, setIsOpen }) => {
  // https://stackoverflow.com/questions/52879362/gatsby-open-modal-window-on-button-click
  Modal.setAppElement("#___gatsby");

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      shouldCloseOnEsc={true}
      contentLabel="Commercial Inquiry"
      className="form-modal"
      overlayClassName="form-modal-overlay"
    >
      <button onClick={(e) => closeModal()} className="close">
        <svg width="34"
            height="34"
            viewBox="0 0 26 26"
            fill="currentColor">
          <path d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25
            C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M18.78,17.394l-1.388,1.387c-0.254,0.255-0.67,0.255-0.924,0
            L13,15.313L9.533,18.78c-0.255,0.255-0.67,0.255-0.925-0.002L7.22,17.394c-0.253-0.256-0.253-0.669,0-0.926l3.468-3.467
            L7.221,9.534c-0.254-0.256-0.254-0.672,0-0.925l1.388-1.388c0.255-0.257,0.671-0.257,0.925,0L13,10.689l3.468-3.468
            c0.255-0.257,0.671-0.257,0.924,0l1.388,1.386c0.254,0.255,0.254,0.671,0.001,0.927l-3.468,3.467l3.468,3.467
            C19.033,16.725,19.033,17.138,18.78,17.394z"/>
        </svg>
      </button>
      <Iframe
        className="airtable-embed airtable-dynamic-height"
        url="https://airtable.com/embed/shrpymVxK8iVucrNL?backgroundColor=green"
        width="100%"
        height="100%"
        styles="background: transparent; border: 1px solid #ccc; border-radius: 0px;"
      ></Iframe>
    </Modal>
  );
};

export default CommercialInquiryModal;
