import React, { useState } from "react";
import AppStore from "../svg/app-store.svg";
import PlayStore from "../svg/play-store.svg";
import ClotoLogo from "../images/cloto-logo-with-text.png";

function Header({ selected }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  let selStyle =
    "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium";
  let deselStyle =
    "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium";

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex items-center px-2 lg:px-0">
            <div className="flex-shrink-0">
              <a href="/">
                <img className="block h-10 w-auto" src={ClotoLogo}/>
              </a>
            </div>
            <div className="hidden lg:block lg:ml-6">
              <div className="flex space-x-4">
                <a
                  href="/how-cloto-works"
                  className={selected === 1 ? selStyle : deselStyle}
                >
                  How it Works
                </a>
                <a
                  href="/plans-and-pricing"
                  className={selected === 2 ? selStyle : deselStyle}
                >
                  Plans and Pricing
                </a>
                <a
                  href="/faq"
                  className={selected === 3 ? selStyle : deselStyle}
                >
                  FAQ
                </a>
              </div>
            </div>
          </div>
          <div className="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
            <a href="https://itunes.apple.com/us/app/cloto/id1175238344">
              <AppStore className="mr-2 w-32 hidden sm:block"></AppStore>
            </a>
            <a href="https://play.google.com/store/apps/details?id=ca.cloto.userclient.android">
              <PlayStore className="w-32 hidden sm:block"></PlayStore>
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              onClick={(e) => toggleMenu()}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={menuOpen ? "hidden h-6 w-6" : "block h-6 w-6"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={menuOpen ? "block h-6 w-6" : "hidden h-6 w-6"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={menuOpen ? "block" : "hidden lg:hidden"}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <a
            href="/how-cloto-works"
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            How it Works
          </a>
          <a
            href="/plans-and-pricing"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            Plans and Pricing
          </a>
          <a
            href="/faq"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          >
            FAQ
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Header;
